import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from "../Button/Button";
import "./styles.scss";
import {  useSelector } from "react-redux";
import { app_url } from "../../../utils/api";
import { Select, MenuItem } from "@mui/material";
import FormSelect from "../Form-select/FormSelect";
import FormDropdownCat from "../FormDropdown/FormDropdownCat";

const ManageSubCatOrder = (props) => {
  const allSubCategories = useSelector((state) => state.dashboard?.allSubCategories);
  const allCategories = useSelector((state) => state.dashboard?.allCategories);
  const [items, setItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(""); // State to track selected category


  // useEffect(() => {
  //   setItems(allSubCategories); // Initialize subcategories
  // }, []);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const updatedItems = Array.from(items);
    const [movedItem] = updatedItems.splice(source.index, 1);
    updatedItems.splice(destination.index, 0, movedItem);

    setItems(updatedItems);
  };

  const updateCategoryOrder = async (updatedItems) => {
    try {
      const response = await fetch(app_url + `/manage/sub-categories`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ categories: updatedItems }),
      });
      const data = await response.json();
      props.handleManageSubCategoryModalClose();
      console.log("Updated order saved:", data);
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const handleCategoryChange = (event) => {
    //const selectedCategoryId = event.target.value;
    const category = allCategories.find((cat) => cat._id === event)?.categoryName[0]?.name;
    setSelectedCategory(category);
   
    // Filter subcategories based on the selected category
    const filteredSubCategories = allSubCategories.filter(
      (subCat) => subCat.category === category
    );
  
    console.log("Filtered Subcategories:", category);
    setItems(filteredSubCategories); // Update items to the filtered subcategories
  };
  
  console.log("filter",items);
  return (
    <React.Fragment>
      <Modal
        className="AddPreference-Modal"
        show={props.showManageSubCategoryModal}
        onHide={props.handleManageSubCategoryModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage  Sub-Category</Modal.Title>
        </Modal.Header>
        
        {/* Category Selection Dropdown */}
        <div style={{ position: "relative" }}>
              <FormSelect
                icon="fa fa-users icon"
                size="15px"
                type="text"
                name="categoryName"
                placeholder="Category"
                value={selectedCategory}
                handleChange={handleCategoryChange}
              />
              <FormDropdownCat
                changeCatHandler={handleCategoryChange}
                selectedCat={selectedCategory}
                allCategories={allCategories}
              />
            </div>

        
        <Modal.Body>
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="droppable">
      {(provided) => (
        <div
          className="scrollable-list"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {items.length > 0 ? (
              items.map((item, index) => (
                <Draggable key={item._id} draggableId={item._id} index={index}>
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        padding: "8px",
                        margin: "4px 0",
                        border: "1px solid lightgray",
                        borderRadius: "4px",
                        backgroundColor: "white",
                      }}
                    >
                      {item.subCategoryName[0].name}
                    </li>
                  )}
                </Draggable>
              ))
            ) : (
              <li>No subcategories available for this category.</li>
            )}
            {provided.placeholder}
          </ul>
        </div>
      )}
    </Droppable>
  </DragDropContext>
</Modal.Body>

        
        <Modal.Footer>
          <button
           type="button"
            className="btn btn-primary add-new-patient-btn shadow-none"
            onClick={() => updateCategoryOrder(items.map((el) => el._id))}
          >
            Update Order
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ManageSubCatOrder;
