import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import memoizeOne from 'memoize-one';

import Table from './Table';
import Pagination from '../../common/Pagination/Pagination';
import Search from '../../common/Search/Search';
import DeleteModal from '../../common/Modal/DeleteModal';
import AddAffirmationModal from '../../common/Modal/AddAffirmation';
import Button from '../../common/Button/Button';
import { validateAddAffirmation, validateAddPrompt } from '../../../validations/add-validation';
import {
  getAllAffirmations,
  getJournals,
  getAllSubCategories,
  getAllPreferences,
  getAllAffirmationsToExport,
  addAffirmation,
  uploadCSVFile,
  addAffirmationTranslations,
  searchAffirmations,
  deleteAffirmation,
  deleteJounral,
  blockOrUnblockManager,
  getAllPromptCategories,
  addPrompt,
  addJournalTranslations,
} from '../../../actions/dashboardActions';
import './styles.scss';
import SubscriptionModal from '../../common/Modal/SubscriptionModal';
import { RotatingLines } from 'react-loader-spinner';
import AddSuggestions from '../../common/Modal/AddSuggestions';
import AddPrompt from '../../common/Modal/AddPrompt';
import DeleteModalJounral from '../../common/Modal/DeleteJounral';

const JournalSuggestions = (props) => {
  const [token, setToken] = useState(localStorage.getItem('jwtToken'));
  const [id, setId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddAffirmationModal, setShowAddAffirmationModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [csvImported, setCsvImported] = useState(true);
  const [affirmationsLoaded, setAffirmationsLoaded] = useState(true);
  const [affirmation, setAffirmation] = useState({
    title: '',
    category: ''
  });
  const [affirmations, setAffirmations] = useState([]);
  const [query, setQuery] = useState('');
  const [csvFile, setCsvFile] = useState('');
  const [headers] = useState([
    { label: 'Sr no.', key: 'srNo' },
    { label: 'Id', key: 'id' },
    { label: 'English', key: 'english' },
    { label: 'Arabic', key: 'arabic' },
    { label: 'Russian', key: 'russian' },
    { label: 'German', key: 'german' },
    { label: 'French', key: 'french' },
    { label: 'Spanish', key: 'spanish' },
    { label: 'Portugese', key: 'portugese' },
    { label: 'SubCategory', key: 'subCategory' },
    { label: 'Preference', key: 'preference' },
  ]);
  const [data, setData] = useState([]);
  const [pageLimit] = useState(500);
  const [currentPage, setCurrentPage] = useState(1);

  const memoizedGetAllAffirmations = useCallback(
    memoizeOne((token, page, pageLimit) => {
      props.getJournals(token, page, pageLimit);
    })
  );

  useEffect(() => {
    setAffirmationsLoaded(false);
    
    memoizedGetAllAffirmations(token, currentPage, pageLimit);
    props.getAllPromptCategories(token);
    props.getAllPreferences(token);
    props.getAllAffirmationsToExport(token);
  }, []);

  useEffect(() => {
    if (props.allJournals && props.allJournals.length > 0) {
      setData(
        props.allAffirmationstoExport.map((row, index) => {
          const rowData = {
            srNo: index + 1,
            id: row._id,
            subCategory: row.subCategory || 'General',
            preference: row.type || 'Fundamentals',
          };

          row.title.forEach((title) => {
            switch (title.locale) {
              case 'en':
                rowData.english = title.name;
                break;
              case 'ar':
                rowData.arabic = title.name || '';
                break;
              case 'ru':
                rowData.russian = title.name || '';
                break;
              case 'de':
                rowData.german = title.name || '';
                break;
              case 'fr':
                rowData.french = title.name || '';
                break;
              case 'es':
                rowData.spanish = title.name || '';
                break;
              case 'pt-PT':
                rowData.portugese = title.name || '';
                break;
              default:
                break;
            }
          });
          return rowData;
        })
      );

      setAffirmations(
        props.allJournals.map((aff) => {
          const copyAffObj = {
            journalId: aff._id,
            category: aff.category,
            journalNameArr: [...aff?.title, ...Array(6).fill({ locale: '', name: '' })],
            journalTitle: [
              { locale: 'ar', name: '' },
              { locale: 'ru', name: '' },
              { locale: 'de', name: '' },
              { locale: 'fr', name: '' },
              { locale: 'es', name: '' },
              { locale: 'pt-PT', name: '' },
            ],
          };

          return copyAffObj;
        })
      );
    }
  }, []);

  const handleDeleteModalOpen = (id) => {
    setShowDeleteModal(true);
    setId(id);
  };

  const handleAddAffirmationModalOpen = () => {
    setShowAddAffirmationModal(true);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const handleAddAffirmationModalClose = () => {
    setShowAddAffirmationModal(false);
  };

  const handleSubscriptionModalOpen = (id) => {
    setShowSubscriptionModal(true);
    setId(id);
  };

  const handleYesBtnClick = () => {
    props.deleteJounral(id, token);
    setShowDeleteModal(false);
    props.getJournals(token);
  };

  const handleYesBtnClickSubscription = () => {};

  const handleNoBtnClick = () => {
    setShowDeleteModal(false);
  };

  const blockOrUnblockManager = (id, status) => {
    const data = { id, status };
    props.blockOrUnblockManager(token, data);
  };

  const handleChange = (e) => {
    setAffirmation((prevAffirmation) => ({
      ...prevAffirmation,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectChange = (newValue) => {
    setAffirmation((prevAffirmation) => ({
      ...prevAffirmation,
      ...newValue,
    }));
  };

  const handleAddAffirmationSubmit = (e) => {
    e.preventDefault();

    const { title, subCategory } = affirmation;
console.log("journ post data",affirmation);

    const result = validateAddPrompt(title, subCategory);
    if (result) return toast.error(result);
  const postData={
    title,
    category:subCategory
  }
    props.addPrompt(postData, token);
    setShowAddAffirmationModal(false);
  };

  const handleAddAffirmationTranslations = (index) => {
    if (affirmations[index]) {
      props.addJournalTranslations(affirmations[index], token);
      console.log("chek pas data",affirmations[index], token);
      
      setShowAddAffirmationModal(false);
    }
  };

  const updateCSVLoader = () => {
    setCsvImported(true);
  };

  const handleCSVUpload = () => {
    const csvFileUpload = new FormData();
    csvFileUpload.append('csvFile', csvFile);

    props.uploadCSVFile(token, csvFile, currentPage, pageLimit, updateCSVLoader);
  };

  const handleImportFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file && file.type === 'text/csv') {
      setCsvFile(file);
      setCsvImported(false);
      handleCSVUpload();
    } else {
      toast.error('Unsupported file type. Only CSV files are allowed.');
    }
  };

  const handleSearch = (query) => {
    setQuery(query);

    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      props.searchAffirmations(token, query);
    }, 2000);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    props.getJournals(token, page, pageLimit);
  };

  const handleLangInputChange = (index, langInx, value) => {
    console.log("check Change data",affirmations,index,langInx,value);
    
    const copyAffirmations = [...affirmations];
    copyAffirmations[index].journalTitle[langInx].name = value;
    setAffirmations(copyAffirmations);
  };

  const profile = props.dashboard.adminProfileData;
console.log("Journla Page Data",props.dashboard.promptCategories);

  return (
    <React.Fragment>
      {affirmationsLoaded ? (
        <div className="loader">
          <h3>Loading affirmations...</h3>
          <RotatingLines
            strokeColor="#5a5abe"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      ) : (
        <>
          <div className="manage-patient-wrapper m-pw">
            <div className="row align-items-center mb-4">
              <div className="col-md-6">
                <h5 className="page_heading new-page-heading">
                Journal Suggestions
                </h5>
              </div>
              {/* Uncomment if you want to include import and export functionality */}
              {/* <div className="col-md-6">
                <div className="float-right">
                  <label
                    className="upload-image input-width d-inline"
                    htmlFor="upload-button1"
                  >
                    Import <i className="fa fa-upload ml-2"></i>
                  </label>
                  <input
                    id="upload-button1"
                    name="csv"
                    type="file"
                    accept="text/*"
                    onChange={handleImportFileChange}
                  />
                  <CSVLink
                    filename={"All_Affirmations.csv"}
                    data={data}
                    headers={headers}
                    className="btn"
                    target="_blank"
                  >
                    <button className="btn btn-primary export-btn shadow-none">
                      Export
                      <i className="fa fa-download ml-2"></i>
                    </button>
                  </CSVLink>
                </div>
              </div> */}
            </div>

            {/* Uncomment if you want to include CSV import loader */}
            {/* {!csvImported && (
              <div className="loader">
                <h3>Importing...</h3>
                <RotatingLines
                  strokeColor="#5a5abe"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={!csvImported}
                />
              </div>
            )} */}

            <div className="manage-patient">
              <div className="row search_row">
                <div className="col-md-7 button_col">
                <Button
                    type="button"
                    className="btn btn-primary add-new-patient-btn shadow-none"
                    value="Add Suggestion"
                    handleClick={handleAddAffirmationModalOpen}
                  />
                </div>
                <div className="col-md-5 search_col">
                  <Search
                    icon="fa fa-search icon"
                    size="15px"
                    placeholder="Search Suggestions"
                    styles={{
                      marginBottom: '20px',
                      fontSize: '15px',
                      paddingLeft: '35px',
                    }}
                    search={query}
                    handleSearch={handleSearch}
                  />
                </div>
              </div>

              <div className="table-responsive">
                <Table
                  deleteModalClicked={handleDeleteModalOpen}
                  allAffirmations={affirmations}
                  blockOrUnblockManager={blockOrUnblockManager}
                  handleAddAffirmationTranslations={handleAddAffirmationTranslations}
                  handleLangInputChange={handleLangInputChange}
                  editSubscription={handleSubscriptionModalOpen}
                  data={profile}
                  showSubscription
                />
              </div>
              <div className="row">
                <div className="col-md-6"></div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    paddingTop: '1rem',
                  }}
                  className="col-md-6"
                >
                  {!query && (
                    <Pagination
                      count={props.totalCount}
                      pageLimit={pageLimit}
                      currentPage={currentPage}
                      handlePageClick={handlePageClick}
                    />
                  )}
                  {/* Uncomment if you want to include MUI Pagination */}
                  {/* <Pagination
                    className="dog"
                    count={Math.ceil(totalAffirmations / pageLimit)}
                    variant="outlined"
                    shape="rounded"
                  /> */}
                </div>
              </div>
            </div>
          </div>

          <DeleteModalJounral
            showDeleteModal={showDeleteModal}
            handleDeleteModalClose={handleDeleteModalClose}
            title="Delete Affirmation"
            text="Are you sure you want to delete this affirmation?"
            handleYesBtnClick={handleYesBtnClick}
            handleNoBtnClick={handleNoBtnClick}
          />

          <SubscriptionModal
            showDeleteModal={showSubscriptionModal}
            handleDeleteModalClose={() => setShowSubscriptionModal(false)}
            title="Choose Subscription"
            token={token}
            userId={id}
            handleYesBtnClick={handleYesBtnClickSubscription}
            handleNoBtnClick={handleNoBtnClick}
          />

          <AddPrompt
            showAddAffirmationModal={showAddAffirmationModal}
            title={affirmation.title}
            subCategory={affirmation.subCategory}
            preferenceId={affirmation.preferenceId}
            promptCategories={props.dashboard.promptCategories}
            allPreferences={props.dashboard.allPreferences}
            handleAddAffirmationModalClose={handleAddAffirmationModalClose}
            handleAddAffirmationSubmit={handleAddAffirmationSubmit}
            handleAddAffirmationTranslations={handleAddAffirmationTranslations}
            handleChange={handleChange}
            handleSelectChange={handleSelectChange}
          />
        </>
      )}
    </React.Fragment>
  );
};

JournalSuggestions.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getJournals: PropTypes.func.isRequired,
  getAllAffirmationsToExport: PropTypes.func.isRequired,
  getAllPromptCategories:PropTypes.func.isRequired,
  addAffirmation: PropTypes.func.isRequired,
  addPrompt:PropTypes.func.isRequired,
  uploadCSVFile: PropTypes.func.isRequired,
  addAffirmationTranslations: PropTypes.func.isRequired,
  searchAffirmations: PropTypes.func.isRequired,
  deleteAffirmation: PropTypes.func.isRequired,
  deleteJounral:PropTypes.func.isRequired,
  blockOrUnblockManager: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  allAffirmations: state.dashboard.allAffirmations,
  allJournals:state.dashboard.allJournals,
  allSubCategories: state.dashboard.allSubCategories,
  promptCategories:state.dashboard.promptCategories,
  allPreferences: state.dashboard.allPreferences,
  totalCount: state.dashboard.dashboardStats.allAffirmations,
  allAffirmationstoExport: state.dashboard.allAffirmationstoExport,
});

export default connect(mapStateToProps, {
  getJournals,
  getAllSubCategories,
  getAllPromptCategories,
  getAllPreferences,
  getAllAffirmationsToExport,
  addAffirmation,
  addPrompt,
  uploadCSVFile,
  addAffirmationTranslations,
  addJournalTranslations,
  searchAffirmations,
  deleteAffirmation,
  deleteJounral,
  blockOrUnblockManager,
})(JournalSuggestions);
