import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import Table from "./Table";
import Pagination from "../../common/Pagination/Pagination";
import Search from "../../common/Search/Search";
import DeleteModal from "../../common/Modal/DeleteModal";
import AddCategoryModal from "../../common/Modal/AddCategory";
import Button from "../../common/Button/Button";
import {
  validateAddCategory,
  validateAddCategoryTranslation,
} from "../../../validations/add-validation";
import {
  getAllManagers,
  getSingleCategory,
  getAllCategories,
  addCategory,
  addCategoryTranslations,
  searchCategory,
  deleteCategory,
  getAllSubscriptions,
  blockOrUnblockManager,
} from "../../../actions/dashboardActions";
import "./styles.scss";
import SubscriptionModal from "../../common/Modal/SubscriptionModal";
import ManageOrder from "../../common/Modal/ManageOrder";

class ManageCategory extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      id: "",
      showDeleteModal: false,
      showAddCategoryModal: false,
      showManageCategoriesModal:false,
      showSubscriptionModal: false,
      category: {
        categoryName: "",
      },
      categories: [],
      query: "",
      pageLimit: 10,
      currentPage: 1,
    };
  }

  categoryObj = () => ({
    categoryId: "",
    categoryNameVal: "",
    categoryName: [
      {
        locale: "ar",
        name: "",
      },
      {
        locale: "ru",
        name: "",
      },
      {
        locale: "de",
        name: "",
      },
      {
        locale: "fr",
        name: "",
      },
      {
        locale: "es",
        name: "",
      },
      {
        locale: "pt-PT",
        name: "",
      },
    ],
  });

  componentDidMount = () => {
    this.props.getAllCategories(this.state.token);
  };

  componentWillReceiveProps = (nextProps) => {
    console.log("nextPropsss", nextProps, this.state);
    if (nextProps.allCategories && nextProps.allCategories.length > 0) {
      this.setState({
        categories: nextProps.allCategories.map((cat) => {
          const copyCatObj = this.categoryObj();
          copyCatObj.categoryId = cat._id;
          copyCatObj.categoryNameVal = [
            ...cat.categoryName,
            copyCatObj.categoryName,
          ];
          return copyCatObj;
        }),
      });
    }
  };

  deleteModalClicked = (id) => {
    this.setState({ showDeleteModal: true, id });
  };

  addCategoryModalClicked = () => {
    this.setState({ showAddCategoryModal: true });
  };
manageOrderCategory=()=>{
  this.setState({showManageCategoriesModal:true})
}
manageOrderCategoryClose=()=>{
  this.setState({showManageCategoriesModal:false})
}
  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false });
  };

  handleAddCategoryModalClose = () => {
    this.setState({ showAddCategoryModal: false });
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscription: true, id });
  };

  handleYesBtnClick = () => {
    this.props.deleteCategory(this.state.id, this.state.token);
    this.props.getAllCategories(this.state.token);
    this.setState({ showDeleteModal: false });
  };

  handleYesBtnClickSubscription = () => { };

  handleNoBtnClick = () => {
    this.setState({ showDeleteModal: false });
  };

  blockOrUnblockManager = (id, status) => {
    let data = {
      id,
      status,
    };

    this.props.blockOrUnblockManager(this.state.token, data);
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscriptionModal: true, id });
  };

  handleChange = (e) => {
    const category = { ...this.state.category };
    category[e.target.name] = e.target.value;
    this.setState({ category });
  };

  handleCategoryChange = (e) => {
    const categoryName = { ...this.state.categories };
    categoryName[e.target.name] = e.target.value;
    this.setState({ categoryName });
  };

  handleAddCategorySubmit = (e) => {
    e.preventDefault();

    const { categoryName } = this.state.category;

    const result = validateAddCategory(categoryName);
    if (result) return toast.error(result);

    this.props.addCategory(this.state.category, this.state.token);

    this.setState({ showAddCategoryModal: false });
  };

  handleAddCategoryTranslationSubmit = (index) => {
    if (this.state.categories?.[index]) {
      this.props.addCategoryTranslations(
        this.state.categories?.[index],
        this.state.token
      );

      this.setState({ showAddCategoryModal: false });
    }
  };

  handleSearch = (query) => {
    this.setState({ query });
    this.props.searchCategory(this.state.token, query);
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
    this.props.getAllCategories(this.state.token);
  };


  render() {
    const { allCategories } = this.props;
    const totalCategories = allCategories.length;

    const handleLangInputChange = (index, langInx, value) => {
      const copyCategories = [...this.state.categories];
      copyCategories[index].categoryName[langInx].name = value;
      this.setState({ categories: copyCategories });
    };

    const profile = this.props.dashboard.adminProfileData;

    return (
      <React.Fragment>
        <div className="manage-patient-wrapper m-pw">
          <h5 className="page_heading new-page-heading">Manage Categories</h5>
          <div className="manage-patient">
            <div className="row search_row">
              <div className="col-md-7 button_col">
                <Button
                  type="button"
                  className="btn btn-primary add-new-patient-btn shadow-none"
                  value="Add Category"
                  handleClick={this.addCategoryModalClicked}
                />{" "}
                <Button
                  type="button"
                  className="btn btn-primary add-new-patient-btn shadow-none"
                  value="Manage Order"
                  handleClick={this.manageOrderCategory}
                />
              </div>
              <div className="col-md-5 search_col">
                <Search
                  icon="fa fa-search icon"
                  size="15px"
                  placeholder="Search Category"
                  styles={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "35px",
                  }}
                  search={this.state.query}
                  handleSearch={this.handleSearch}
                />
              </div>
            </div>

            <div className="table-responsive">
              <Table
                deleteModalClicked={this.deleteModalClicked}
                allCategories={this.state.categories}

                blockOrUnblockManager={this.blockOrUnblockManager}
                editSubscription={this.subscriptionModalClicked}
                handleLangInputChange={handleLangInputChange}
                handleAddCategoryTranslationSubmit={
                  this.handleAddCategoryTranslationSubmit
                }
                data={profile}
                showSubscription
              />
            </div>
            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6">
                {!this.state.query && (
                  <Pagination
                    count={totalCategories}
                    pageLimit={this.state.pageLimit}
                    currentPage={this.state.currentPage}
                    handlePageClick={this.handlePageClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <DeleteModal
          showDeleteModal={this.state.showDeleteModal}
          handleDeleteModalClose={this.handleDeleteModalClose}
          title="Delete Category"
          text="Are you sure you want to delete this category ?"
          handleYesBtnClick={this.handleYesBtnClick}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <SubscriptionModal
          showDeleteModal={this.state.showSubscriptionModal}
          handleDeleteModalClose={() =>
            this.setState({ showSubscriptionModal: false })
          }
          title="Choose Subscription"
          token={this.state.token}
          userId={this.state.id}
          handleYesBtnClick={this.handleYesBtnClickSubscription}
          handleNoBtnClick={this.handleNoBtnClick}
        />
        <AddCategoryModal
          showAddCategoryModal={this.state.showAddCategoryModal}
          allCategories={this.state.categories}
          handleAddCategoryModalClose={this.handleAddCategoryModalClose}
          handleAddCategorySubmit={this.handleAddCategorySubmit}
          handleChange={this.handleChange}
        />
        <ManageOrder
          showAddCategoryModal={this.state.showManageCategoriesModal}
          allCategories={this.state.categories}
          handleAddCategoryModalClose={this.manageOrderCategoryClose}
          handleAddCategorySubmit={this.handleAddCategorySubmit}
          handleChange={this.handleChange}
        />
      </React.Fragment>
    );
  }
}

ManageCategory.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getAllManagers: PropTypes.func.isRequired,
  getAllCategories: PropTypes.func.isRequired,
  addCategory: PropTypes.func.isRequired,
  addCategoryTranslations: PropTypes.func.isRequired,
  searchCategory: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  blockOrUnblockManager: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  allCategories: state?.dashboard?.allCategories,
});

export default connect(mapStateToProps, {
  getAllManagers,
  getAllCategories,
  addCategory,
  addCategoryTranslations,
  searchCategory,
  deleteCategory,
  blockOrUnblockManager,
  getAllSubscriptions,
})(ManageCategory);
