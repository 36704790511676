import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from "../Button/Button";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories, getAllSubCategories, getAllThemeCategories, updateAllCategories } from "../../../actions/dashboardActions";
import { app_url } from "../../../utils/api";

const ManageThemCatOrder = (props) => {
  const allCategories = useSelector((state) => state.dashboard?.allThemesCategoriesData);
  const [items, setItems] = useState(allCategories);
console.log("Get all sub cat",allCategories,items)
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(getAllThemeCategories()); // Dispatch action to get categories
    setItems(allCategories);
  }, [allCategories, dispatch]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const updatedItems = Array.from(items);
    const [movedItem] = updatedItems.splice(source.index, 1);
    updatedItems.splice(destination.index, 0, movedItem);

    setItems(updatedItems);
    
  };

  const updateCategoryOrder = async (updatedItems) => {
    try {
      const response = await fetch(app_url + `/all/theme-categories-order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ categories: updatedItems }),
      });
      const data = await response.json();
      props.handleCloseModal()
      console.log("Updated order saved:", data);
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  return (
    <React.Fragment>
      <Modal
        className="AddPreference-Modal"
        show={props.showMangeCatModal}
        onHide={props.handleCloseModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage Theme Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  className="scrollable-list" // Add a class for the scrollable list
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {items.map((item, index) => (
                      <Draggable key={item._id} draggableId={item._id} index={index}>
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              padding: "8px",
                              margin: "4px 0",
                              border: "1px solid lightgray",
                              borderRadius: "4px",
                              backgroundColor: "white",
                            }}
                          >
                            {item.themeCategory[0].name}
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Modal.Body>
        <Modal.Footer>
          
          <button
          type="button"
            className="btn btn-primary add-new-patient-btn shadow-none"
            onClick={() => updateCategoryOrder(items.map((el) => el._id))}
          > Update Order</button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ManageThemCatOrder;
