import React from "react";

const FormSelect = (props) => {
  // const [subCategories, setSubCategories] = useState(props.options);

  return (
    <React.Fragment>
      <div className="form-group input-icons">
        <i className={props.icon} style={{ fontSize: props.size }}></i>
        <select
          style={{ height: "50px", borderRadius: 8 }}
          name={props.name}
          className="form-select form-control shadow-none"
          value={props.value}
          onChange={props.handleChange}
          readOnly={props.readOnly}
        >
          <option value="" disabled>
            {props.placeholder}
          </option>
          {props?.options?.map((option, index) => {
            const enSubCategory = option?.categoryName?.find(
              (sub) => sub.locale === "en"
            );

            if (enSubCategory) {
              const isDisabled =
                enSubCategory.name === "My Own Affirmations" ||
                enSubCategory.name === "Favorites";

              return (
                <option
                  key={index}
                  value={enSubCategory.name}
                  className="custom-option"
                  disabled={isDisabled}
                >
                  {enSubCategory.name}
                </option>
              );
            } else {
              return null;
            }
          })}
        </select>
      </div>
    </React.Fragment>
  );
};

export default FormSelect;
