import isEmpty from "is-empty";

export const validateAddVoice = (voiceName, voiceLocale, voiceIdentifier) => {
  if (isEmpty(voiceName)) return "Please enter voice name";
  if (voiceName.length < 3) return "Voice name must be atleast 3 characters";
  if (voiceName.length > 255) return "Voice name must be atmost 255 characters";

  if (isEmpty(voiceLocale)) return "Please enter voice locale";
  if (voiceLocale.length < 3) return "Locale must be atleast 2 characters";
  if (voiceLocale.length > 255) return "Locale must be atmost 255 characters";

  if (isEmpty(voiceIdentifier)) return "Please enter voice identifier";
  if (voiceIdentifier.length < 3)
    return "Voice Identifier must be atleast 3 characters";
  if (voiceIdentifier.length > 255)
    return "Voice Identifier must be atmost 255 characters";
};

export const validateEditVoice = (voiceName, voiceLocale, voiceIdentifier) => {
  if (isEmpty(voiceName)) return "Please enter voice name";
  if (voiceName.length < 3) return "Voice name must be atleast 3 characters";
  if (voiceName.length > 255) return "Voice name must be atmost 255 characters";

  if (isEmpty(voiceLocale)) return "Please enter voice locale";
  if (voiceLocale.length < 3) return "Locale must be atleast 2 characters";
  if (voiceLocale.length > 255) return "Locale must be atmost 255 characters";

  if (isEmpty(voiceIdentifier)) return "Please enter voice identifier";
  if (voiceIdentifier.length < 3)
    return "Voice Identifier must be atleast 3 characters";
  if (voiceIdentifier.length > 255)
    return "Voice Identifier must be atmost 255 characters";
};

export const validateAddCharacter = (
  characterType,
  characterImage_sm,
  characterImage_lg
) => {
  if (isEmpty(characterType)) return "Please select the character type";
  if (characterType !== 1 && characterType !== 2 && characterType !== 3)
    return "Invalid character type. Please select a valid character type";

  if (isEmpty(characterImage_sm))
    return "Please upload a small character image";

  if (
    characterImage_sm.type !== "image/jpeg" &&
    characterImage_sm.type !== "image/png" &&
    characterImage_sm.type !== "image/jpg"
  )
    return " Please select a valid image. Allowed image formats are .jpeg , .png and .jpg";

  if (isEmpty(characterImage_lg))
    return "Please upload a large character image";
  if (
    characterImage_lg.type !== "image/jpeg" &&
    characterImage_lg.type !== "image/png" &&
    characterImage_lg.type !== "image/jpg"
  )
    return " Please select a valid image. Allowed image formats are .jpeg , .png and .jpg";
};

export const validateEditCharacter = (
  characterType,
  characterImage_sm,
  characterImage_lg
) => {
  if (isEmpty(characterType)) return "Please select the character type";
  if (characterType !== 1 && characterType !== 2 && characterType !== 3)
    return "Invalid character type. Please select a valid character type";

  if (isEmpty(characterImage_sm))
    return "Please upload a small character image";

  if (
    characterImage_sm.type !== "image/jpeg" &&
    characterImage_sm.type !== "image/png" &&
    characterImage_sm.type !== "image/jpg"
  )
    return "Please select a valid image. Allowed image formats are .jpeg , .png and .jpg";

  if (isEmpty(characterImage_lg))
    return "Please upload a large character image";
  if (
    characterImage_lg.type !== "image/jpeg" &&
    characterImage_lg.type !== "image/png" &&
    characterImage_lg.type !== "image/jpg"
  )
    return "Please select a valid image. Allowed image formats are .jpeg , .png and .jpg";
};

export const validateAddCategory = (categoryName) => {
  if (isEmpty(categoryName)) return "Please enter category name";
  if (categoryName.length < 3) return "Category must be atleast 3 characters";
  if (categoryName.length > 64) return "Category must be atmost 64 characters";
};

export const validateAddTextContent = (text) => {
  if (isEmpty(text)) return "Please enter text content";
  if (text.length < 1) return "Text content must be atleast of 1 charactera";
};

export const validateAddAffirmation = (title, subCategory, preferenceId) => {
  if (isEmpty(title)) return "Please enter affirmation title";
  if (title.length < 3) return "Affirmation title must be atleast 3 characters";
  if (title.length > 510)
    return "Affirmation title must be atmost 510 characters";

  if (isEmpty(subCategory)) return "Please select affirmation's subcategory";
  if (isEmpty(preferenceId)) return "Please select affirmation's preference";
};

export const validateAddPrompt = (title, category) => {
  if (isEmpty(title)) return "Please enter prompt title";
  if (title.length < 3) return "Prompt title must be atleast 3 characters";
  if (title.length > 510)
    return "Prompt title must be atmost 510 characters";

  if (isEmpty(category)) return "Please select Prompt's category";
};

export const validateAddCategoryTranslation = (
  categoryName1,
  categoryName2,
  categoryName3,
  categoryName4,
  categoryName5,
  categoryName6
) => {
  if (isEmpty(categoryName1)) return "Please enter all category translations";
  if (isEmpty(categoryName2)) return "Please enter all category translations";
  if (isEmpty(categoryName3)) return "Please enter all category translations";
  if (isEmpty(categoryName4)) return "Please enter all category translations";
  if (isEmpty(categoryName5)) return "Please enter all category translations";
  if (isEmpty(categoryName6)) return "Please enter all category translations";
};

export const validateAddSubCategory = (
  categoryId,
  subCategoryName,
  backgroundColor,
  subCategoryImage,
  categories
) => {
  if (isEmpty(subCategoryName)) return "Please enter sub-category name";
  if (subCategoryName.length < 3)
    return "Sub-category must be atleast 3 characters";
  if (subCategoryName.length > 64)
    return "Sub-category must be atmost 64 characters";

  if (isEmpty(categoryId)) return "Please select category";

  if (!categories.includes(categoryId))
    return "Please select from a given categories list";

  if (isEmpty(backgroundColor)) return "Please enter the background color";
  if (!backgroundColor.startsWith("#"))
    return "Please enter a hex value ( #FFFFFF )";

  if (isEmpty(subCategoryImage)) return "Please upload a sub-category image";

  if (
    subCategoryImage.type !== "image/jpeg" &&
    subCategoryImage.type !== "image/png" &&
    subCategoryImage.type !== "image/jpg"
  )
    return " Please select a valid image. Allowed image formats are .jpeg , .png and .jpg";
};

export const validateAddPreference = (preference) => {
  if (isEmpty(preference)) return "Please enter preference name";
  if (preference.length < 3)
    return "Preference name must be atleast 3 characters";
  if (preference.length > 64)
    return "Preference name must be atmost 64 characters";
};

function normalizeFontName(fontName) {
  return fontName.toLowerCase().replace(/[_ -]/g, "");
}

function areFontNamesEqual(fontName1, fontName2) {
  return normalizeFontName(fontName1) === normalizeFontName(fontName2);
}

export const validateAddNewTheme = (
  themeCategoryId,
  theme,
  fontColor,
  fontName,
  andriodFontName,
  backgroundColor,
  backgroundOpacity
) => {
  if (isEmpty(themeCategoryId)) return "Please select the theme category";

  if (isEmpty(backgroundColor)) return "Please enter the background color";
  if (!backgroundColor.startsWith("#"))
    return "Please enter a hex value ( #FFFFFF )";

  if (isEmpty(backgroundOpacity)) return "Please enter the background opacity";

  if (isEmpty(fontName) || isEmpty(andriodFontName))
    return "Please select the fontName";

  if (!areFontNamesEqual(fontName, andriodFontName)) {
    return "Please select the same corresponding font name for ios and andriod";
  }

  if (isEmpty(fontColor)) return "Please enter the font color";
  if (!fontColor.startsWith("#")) return "Please enter a hex value ( #FFFFFF )";

  if (isEmpty(theme)) return "Please upload a image for theme";
  if (
    theme.type !== "image/jpeg" &&
    theme.type !== "image/png" &&
    theme.type !== "image/jpg" &&
    theme.type !== "image/gif"
  )
    return " Please select a valid image. Allowed image formats are .jpeg , .png and .jpg and.gif";
};

export const validateAddManager = (
  firstName,
  lastName,
  email,
  phoneNumber,
  code
) => {
  if (isEmpty(firstName)) return "Please enter first name";
  if (firstName.length < 3) return "First name must be atleast 3 characters";
  if (firstName.length > 255) return "First name must be atmost 255 characters";

  if (isEmpty(lastName)) return "Please enter last name";
  if (lastName.length < 3) return "Last name must be atleast 3 characters";
  if (lastName.length > 255) return "Last name must be atmost 255 characters";

  if (isEmpty(email)) return "Please enter email";

  if (isEmpty(phoneNumber)) return "Please enter phone number";
  if (isNaN(phoneNumber)) return "Please enter a valid phone number";
  if (phoneNumber.length !== 10) return "Invalid phone number";
};

export const validateEditManager = (
  firstName,
  lastName,
  email,
  phoneNumber
) => {
  if (isEmpty(firstName)) return "Please enter first name";
  if (firstName.length < 3) return "First name must be atleast 3 characters";
  if (firstName.length > 255) return "First name must be atmost 255 characters";

  if (isEmpty(lastName)) return "Please enter last name";
  if (lastName.length < 3) return "Last name must be atleast 3 characters";
  if (lastName.length > 255) return "Last name must be atmost 255 characters";

  if (isEmpty(email)) return "Please enter email";

  if (isEmpty(phoneNumber)) return "Please enter phone number";
  if (isNaN(phoneNumber)) return "Please enter a valid phone number";
  if (phoneNumber.length !== 10) return "Invalid phone number";
};
