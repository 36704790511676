import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Table from "./Table";
import Pagination from "../../common/Pagination/Pagination";
import Search from "../../common/Search/Search";
import DeleteModal from "../../common/Modal/DeleteModal";
import AddCategoryModal from "../../common/Modal/AddCategory";
import Button from "../../common/Button/Button";
import {
  validateAddCategory,
} from "../../../validations/add-validation";
import {
  getAllManagers,
  getAllCategories,
  addCategory,
  addCategoryTranslations,
  searchCategory,
  deleteCategory,
  blockOrUnblockManager,
  getAllPromptCategories,
  addPromptCategory,
} from "../../../actions/dashboardActions";
import "./styles.scss";
import SubscriptionModal from "../../common/Modal/SubscriptionModal";

const PrommptCategory = () => {
  const dispatch = useDispatch();
  
  const token = localStorage.getItem("jwtToken");
  
  const [id, setId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [category, setCategory] = useState({ categoryName: "" });
  const [categories, setCategories] = useState([]);
  const [query, setQuery] = useState("");
  const [pageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const allCategories = useSelector((state) => state?.dashboard?.promptCategories || []);

  const categoryObj = () => ({
    categoryId: "",
    categoryNameVal: "",
    categoryName: [
      { locale: "ar", name: "" },
      { locale: "ru", name: "" },
      { locale: "de", name: "" },
      { locale: "fr", name: "" },
      { locale: "es", name: "" },
      { locale: "pt-PT", name: "" },
    ],
  });

  useEffect(() => {
    dispatch(getAllPromptCategories(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (allCategories && allCategories.length > 0) {
      setCategories(
        allCategories.map((cat) => {
          const copyCatObj = categoryObj();
          copyCatObj.categoryId = cat._id;
          copyCatObj.categoryNameVal = [...cat.categoryName, copyCatObj.categoryName];
          return copyCatObj;
        })
      );
    }
  }, [allCategories]);

  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleAddCategoryModalClose = () => setShowAddCategoryModal(false);
  const handleNoBtnClick = () => setShowDeleteModal(false);

  const handleDeleteCategory = () => {
    dispatch(deleteCategory(id, token));
    dispatch(getAllCategories(token));
    setShowDeleteModal(false);
  };

  const handleAddCategorySubmit = (e) => {
    e.preventDefault();
    const { categoryName } = category;

    const result = validateAddCategory(categoryName);
    if (result) return toast.error(result);

    dispatch(addPromptCategory(category, token));
    setShowAddCategoryModal(false);
  };

  const handleAddCategoryTranslationSubmit = (index) => {
    if (categories?.[index]) {
      dispatch(addCategoryTranslations(categories?.[index], token));
      setShowAddCategoryModal(false);
    }
  };

  const handleSearch = (query) => {
    setQuery(query);
    dispatch(searchCategory(token, query));
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    dispatch(getAllCategories(token));
  };

  const handleLangInputChange = (index, langInx, value) => {
    const copyCategories = [...categories];
    copyCategories[index].categoryName[langInx].name = value;
    setCategories(copyCategories);
  };

  return (
    <>
      <div className="manage-patient-wrapper m-pw">
        <h5 className="page_heading new-page-heading">Manage Categories</h5>
        <div className="manage-patient">
          <div className="row search_row">
            <div className="col-md-7 button_col">
              <Button
                type="button"
                className="btn btn-primary add-new-patient-btn shadow-none"
                value="Add Category"
                handleClick={() => setShowAddCategoryModal(true)}
              />
            </div>
            <div className="col-md-5 search_col">
              <Search
                icon="fa fa-search icon"
                size="15px"
                placeholder="Search Category"
                styles={{
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "35px",
                }}
                search={query}
                handleSearch={handleSearch}
              />
            </div>
          </div>

          <div className="table-responsive">
            <Table
              deleteModalClicked={(id) => setShowDeleteModal(true) && setId(id)}
              allCategories={categories}
              blockOrUnblockManager={(id, status) => dispatch(blockOrUnblockManager(token, { id, status }))}
              editSubscription={() => setShowSubscriptionModal(true)}
              handleLangInputChange={handleLangInputChange}
              handleAddCategoryTranslationSubmit={handleAddCategoryTranslationSubmit}
              showSubscription
            />
          </div>
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              {!query && (
                <Pagination
                  count={allCategories.length}
                  pageLimit={pageLimit}
                  currentPage={currentPage}
                  handlePageClick={handlePageClick}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDeleteModalClose={handleDeleteModalClose}
        title="Delete Category"
        text="Are you sure you want to delete this category ?"
        handleYesBtnClick={handleDeleteCategory}
        handleNoBtnClick={handleNoBtnClick}
      />

      <SubscriptionModal
        showDeleteModal={showSubscriptionModal}
        handleDeleteModalClose={() => setShowSubscriptionModal(false)}
        title="Choose Subscription"
        token={token}
        userId={id}
        handleYesBtnClick={() => {}}
        handleNoBtnClick={handleNoBtnClick}
      />

      <AddCategoryModal
        showAddCategoryModal={showAddCategoryModal}
        handleAddCategoryModalClose={handleAddCategoryModalClose}
        handleAddCategorySubmit={handleAddCategorySubmit}
        handleChange={(e) => setCategory({ ...category, [e.target.name]: e.target.value })}
      />
    </>
  );
};

export default PrommptCategory;
