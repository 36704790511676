import React, { useCallback, useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";

import Button from "../Button/Button";
import FormInput from "../Form-Input/FormInput";

import LazyLoadImage from "../Lazy/Image";

import FormDropdownSub from "../FormDropdown/FormDropdownSub";
import FormDropdownPref from "../FormDropdown/FormDropdownPref";
import "./styles.scss";
import FormDropdownPrompt from "../FormDropdown/FormDropdownPrompt";

const AddPrompt = (props) => {
  const [selectedCat, setSelectedCat] = useState(props.newSubCategory);
  const [selectedPreference, setSelectedPreference] = useState(
    props.preference
  );

  useEffect(() => {
    props.handleSelectChange({
      subCategory: selectedCat,
      preferenceId: selectedPreference,
    });
  }, [selectedCat, selectedPreference]);

  const changeSubCatHandler = useCallback(
    (value) => {
      setSelectedCat(value);
    },
    [selectedCat]
  );


console.log("Prompt Categories",props.promptCategories);

  const getSelectedCat = () => {
    let res = null;

    if (
      selectedCat &&
      props.promptCategories.findIndex((p) => p?.["_id"] == selectedCat) > -1
    ) {
      const selectedCatObj =
        props.promptCategories[
          props.promptCategories.findIndex((p) => p?.["_id"] == selectedCat)
        ];
      res = selectedCatObj?.categoryName[0]?.name;
    }
    return res;
  };


  return (
    <React.Fragment>
      <Modal
        className="AddAffirmation-Modal"
        show={props.showAddAffirmationModal}
        onHide={props.handleAddAffirmationModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Prompt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={props.handleAddAffirmationSubmit}>
            <FormInput
              icon="fa fa-lightbulb icon"
              size="15px"
              type="text"
              name="title"
              placeholder="Prompt"
              value={props.title}
              handleChange={props.handleChange}
            />
            <div style={{ position: "relative" }}>
              <FormInput
                icon="fa fa-lightbulb icon dropdown"
                size="15px"
                type="text"
                name="category"
                placeholder="Prompt Category"
                value={getSelectedCat()}
                handleChange={props.handleChange}
              />
              <FormDropdownPrompt
                changeSubCatHandler={changeSubCatHandler}
                selectedCat={selectedCat}
                promptCategories={props.promptCategories}
              />
            </div>
            
            <Button
              type="submit"
              value="Add  Suggestion"
              className="btn btn-primary btn-block add-patient-btn shadow-none"
            />
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddPrompt;
