import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Button from "../../common/Button/Button";
import Table from "./Table";
import Search from "../../common/Search/Search";
import DeleteModal from "../../common/Modal/DeleteModal";
import { validateAddTextContent } from "../../../validations/add-validation";

import {
  getAllThemeCategories,
  addNewThemeCategory,
  updateThemeCategory,
  searchThemeCategory,
  deleteThemeCategory,
  getAllSubscriptions,
  blockOrUnblockManager,
} from "../../../actions/dashboardActions";
import "./styles.scss";
import SubscriptionModal from "../../common/Modal/SubscriptionModal";
import AddThemeCategoryModal from "../../common/Modal/AddThemeCategory";
import ManageThemCatOrder from "../../common/Modal/ManageThemeCategoryOrder";

class ManageThemesCategory extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      id: "",
      showDeleteModal: false,
      showManageThemeCatModal:false,
      showAddCategoryModal: false,
      showSubscriptionModal: false,
      text: [],
      category: {
        categoryName: "",
      },
      newText: "",
      categories: [],
      query: "",
      pageLimit: 10,
      currentPage: 1,
    };
  }

  categoryObj = () => ({
    categoryId: "",
    categoryNameVal: "",
    categoryName: [
      {
        locale: "es",
        name: "",
      },
      {
        locale: "ar",
        name: "",
      },
      {
        locale: "fr",
        name: "",
      },
      {
        locale: "ru",
        name: "",
      },
      {
        locale: "pt-PT",
        name: "",
      },
      {
        locale: "de",
        name: "",
      },
    ],
  });

  componentDidMount = () => {
    this.props.getAllThemeCategories(this.state.token,'all');
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.allThemesCategoriesData && nextProps.allThemesCategoriesData.length > 0) {
      // console.log("allThemesCategoriesDatassssss", nextProps.allThemesCategoriesData);
    }
  };

  deleteModalClicked = (id) => {
    this.setState({ showDeleteModal: true, id });
  };

  addCategoryModalClicked = () => {
    this.setState({ showAddCategoryModal: true });
  };
manageThemeCatshowModal=()=>{
  this.setState({showManageThemeCatModal:true})
}
handleManageCatShowClose=()=>{
  this.setState({showManageThemeCatModal:false})
}
  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false });
  };

  handleAddCategoryModalClose = () => {
    this.setState({ showAddCategoryModal: false });
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscription: true, id });
  };

  handleYesBtnClick = () => {
    this.props.deleteThemeCategory(this.state.id, this.state.token);
    this.setState({ showDeleteModal: false });
    this.props.getAllThemeCategories(this.state.token,'all');
  };

  handleYesBtnClickSubscription = () => {};

  handleNoBtnClick = () => {
    this.setState({ showDeleteModal: false });
  };

  blockOrUnblockManager = (id, status) => {
    let data = {
      id,
      status,
    };

    this.props.blockOrUnblockManager(this.state.token, data);
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscriptionModal: true, id });
  };

  handleTextChange = (e) => {
    const newChangeText = [...this.state.text];

    const dataIndx = e.target.getAttribute("dataIndex");

    if (newChangeText?.[dataIndx]) {
      const existIndx = newChangeText[dataIndx]["contexts"].findIndex(
        (p) => p.locale === e.target.getAttribute("name")
      );
      if (existIndx > -1) {
        newChangeText[dataIndx]["contexts"][existIndx].name = e.target.value;
      } else {
        newChangeText[dataIndx]["contexts"] = [
          ...newChangeText[dataIndx]["contexts"],
          { locale: e.target.getAttribute("name"), name: e.target.value },
        ];
      }
    } else {
      newChangeText[dataIndx] = {
        textId: "",
        contexts: [
          {
            locale: e.target.getAttribute("name"),
            name: e.target.value,
          },
        ],
      };
    }
    this.setState({
      text: newChangeText,
    });
  };

  handleChange = (e) => {
    this.setState({ newText: e.target.value });
  };

  handleAddTextContent = (e) => {
    e.preventDefault();

    const result = validateAddTextContent(this.state.newText);
    if (result) return toast.error(result);

    this.props.addNewThemeCategory(this.state.newText, this.state.token);

    this.setState({ showAddCategoryModal: false });
  };

  handleTextUpdateSubmit = (index) => {
    if (this.state.text) {
      this.props.updateThemeCategory(this.state.text, this.state.token);

      this.setState({ showAddCategoryModal: false });
    }
  };

  handleSearch = (query) => {
    this.setState({ query });
    this.props.searchThemeCategory(this.state.token, query);
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
    this.props.getAllThemeCategories(this.state.token);
  };

  render() {
    const { allThemesCategoriesData } = this.props;

    const profile = this.props.dashboard.adminProfileData;
    return (
      <React.Fragment>
        <div className="manage-patient-wrapper m-pw">
          <h5 className="page_heading">Manage Theme Category</h5>
          <hr />
          <div className="manage-patient">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              className="row search_row"
            >
              <div className="col-md-7 button_col">
                <Button
                  type="button"
                  className="btn btn-primary add-new-patient-btn shadow-none"
                  value="Add Theme Category"
                  handleClick={this.addCategoryModalClicked}
                />{" "}
                  <Button
                  type="button"
                  className="btn btn-primary add-new-patient-btn shadow-none"
                  value="Manage Order"
                  handleClick={this.manageThemeCatshowModal}
                />
              </div>
              <div className="col-md-5 search_col">
                <Search
                  icon="fa fa-search icon"
                  size="15px"
                  placeholder="Search Text Contents"
                  styles={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "35px",
                  }}
                  search={this.state.query}
                  handleSearch={this.handleSearch}
                />
              </div>
            </div>

            <div className="table-responsive">
              <Table
                deleteModalClicked={this.deleteModalClicked}
                allCategories={this.state.categories}
                allThemesCategoriesData={this.props.allThemesCategoriesData}
                blockOrUnblockManager={this.blockOrUnblockManager}
                editSubscription={this.subscriptionModalClicked}
                handleTextChange={this.handleTextChange}
                text={this.state.text}
                handleTextUpdateSubmit={this.handleTextUpdateSubmit}
                data={profile}
                showSubscription
              />
            </div>
            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6">
                {/* {!this.state.query && (
                  <Pagination
                    count={totalTextContents}
                    pageLimit={this.state.pageLimit}
                    currentPage={this.state.currentPage}
                    handlePageClick={this.handlePageClick}
                  />
                )} */}
              </div>
            </div>
          </div>
        </div>

        <DeleteModal
          showDeleteModal={this.state.showDeleteModal}
          handleDeleteModalClose={this.handleDeleteModalClose}
          title="Delete Category"
          text="Are you sure you want to delete this Theme Category ?"
          handleYesBtnClick={this.handleYesBtnClick}
          handleNoBtnClick={this.handleNoBtnClick}
        />
<ManageThemCatOrder
allThemesCategoriesData={this.props.allThemesCategoriesData}
showMangeCatModal={this.state.showManageThemeCatModal}
handleCloseModal={this.handleManageCatShowClose}
/>
        <SubscriptionModal
          showDeleteModal={this.state.showSubscriptionModal}
          handleDeleteModalClose={() =>
            this.setState({ showSubscriptionModal: false })
          }
          title="Choose Subscription"
          token={this.state.token}
          userId={this.state.id}
          handleYesBtnClick={this.handleYesBtnClickSubscription}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <AddThemeCategoryModal
          showAddThemeCategoryModal={this.state.showAddCategoryModal}
          handleAddThemeCategoryModalClose={this.handleAddCategoryModalClose}
          handleAddNew={this.handleAddTextContent}
          handleChange={this.handleChange}
        />
      </React.Fragment>
    );
  }
}

ManageThemesCategory.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getAllThemeCategories: PropTypes.func.isRequired,
  addNewThemeCategory: PropTypes.func.isRequired,
  updateThemeCategory: PropTypes.func.isRequired,
  searchThemeCategory: PropTypes.func.isRequired,
  deleteThemeCategory: PropTypes.func.isRequired,
  blockOrUnblockManager: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  allThemesCategoriesData: state?.dashboard?.allThemesCategoriesData,
});

export default connect(mapStateToProps, {
  getAllThemeCategories,
  addNewThemeCategory,
  updateThemeCategory,
  searchThemeCategory,
  deleteThemeCategory,
  blockOrUnblockManager,
  getAllSubscriptions,
})(ManageThemesCategory);
