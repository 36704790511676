import {
  IS_LOADING,
  ADMIN_PROFILE_DATA,
  FAQ,
  ABOUT,
  HELP,
  TERM,
  ALL_SUBSCRIPTIONS,
  SINGLE_FAQ,
  VERIFY_SUCCESS,
  VERIFY_ERROR,
  GET_QUESTION,
  GET_MANAGERS,
  GET_MANAGER,
  GET_STAFF,
  GET_STAFFS,
  GET_CMS,
  SINGLE_BLOG,
  BLOG,
  GET_TRANSACTIONS,
  DASHBOARD_STATS,
  GET_CATEGORIES,
  GET_SUBCATEGORIES,
  GET_PROMPTCATEGORIES,
  SINGLE_CATEGORY,
  GET_MODULES,
  GET_VOICES,
  GET_CHARACTERS,
  GET_USERS,
  GET_JOURNALS,
  GET_AFFIRMATIONS,
  GET_THEMESANDFONTS,
  FAQS,
  ADMIN_PROFILE,
  CATEGORY_TRANSLATIONS,
  GET_PREFERENCES,
  GET_SINGLE_VOICE,
  GET_SINGLE_USER,
  GET_SINGLE_CHARACTER,
  GET_SINGLE_CATEGORY,
  GET_SINGLE_PREFERENCE,
  GET_SINGLE_JOUNRAL,
  GET_SINGLE_SUBCATEGORY,
  GET_SINGLE_AFFIRMATION,
  GET_SINGLE_THEME,
  GET_TEXT_CONTENTS,
  GET_SINGLE_TEXT_CONTENTS,
  GET_THEME_CATEGORIES,
  GET_THEME_CATEGORIES_DATA,
  GET_SINGLE_THEME_CATEGORY,
  GET_AFFIRMATIONS_TO_EXPORT,
} from "../actions/types";

const initialState = {
  loading: false,
  adminProfileData: {},
  dashboardStats: {},
  faq: [],
  about: [],
  help: [],
  term: {},
  subscriptions: [],
  singleFAQ: {},
  verifyError: "",
  verifySuccess: "",
  singleQuestion: {},
  allManagers: [],
  singleManagerInfo: {},
  allStaffs: [],
  singleCaptain: {},
  staffs: [],
  cms: [],
  vessels: [],
  blog: [],
  allTransactions: [],
  allPreferences: [],
  singlePreference: {},
  allCategories: [],
  singleCategory: {},
  allSubCategories: [],
  singleSubcategory: {},
  allVoices: [],
  singleVoice: {},
  allCharacters: [],
  singleCharacter: {},
  allUsers: [],
  singleUser: [],
  allJournals:[],
  allAffirmations: [],
  affirmationsLoaded: false,
  singleAffirmation: {},
  singleJounral: {},
  allThemesAndFonts: [],
  allThemesCategories: [],
  singleThemeCategory:{},
  singleTheme: {},
  faqs: [],
  categoryTranslations: {},
  singleTextContent: {},
  allTextContents: [],
  allAffirmationstoExport: [],
  promptCategories:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case DASHBOARD_STATS:
      return {
        ...state,
        dashboardStats: action.payload,
      };
    case ADMIN_PROFILE_DATA:
      return {
        ...state,
        adminProfileData: action.payload,
      };
    case FAQ:
      return {
        ...state,
        faq: action.payload,
      };
    case SINGLE_FAQ:
      return {
        ...state,
        singleFAQ: action.payload,
      };
    case ABOUT:
      return {
        ...state,
        about: action.payload,
      };
    case HELP:
      return {
        ...state,
        help: action.payload,
      };
    case TERM:
      return {
        ...state,
        term: action.payload,
      };
    case ALL_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        verifySuccess: action.payload,
      };
    case VERIFY_ERROR:
      return {
        ...state,
        verifyError: action.payload,
      };
    case GET_QUESTION:
      return {
        ...state,
        singleQuestion: action.payload,
      };
    case GET_MANAGERS:
      return {
        ...state,
        allManagers: action.payload,
      };
    case GET_MANAGER:
      return {
        ...state,
        singleManagerInfo: action.manager,
      };

    case GET_STAFFS:
      return {
        ...state,
        allStaffs: [...action.payload],
      };
    case GET_STAFF:
      return {
        ...state,
        singleCaptain: action.payload,
      };
    case GET_CMS:
      return {
        ...state,
        cms: action.payload,
      };
    case BLOG:
      return {
        ...state,
        blog: action.payload,
      };
    case SINGLE_BLOG:
      return {
        ...state,
        singleBlog: action.payload,
      };
    case GET_TRANSACTIONS:
      return {
        ...state,
        allTransactions: action.payload,
      };
    case GET_PREFERENCES:
      return {
        ...state,
        allPreferences: action.payload,
      };
    case GET_SINGLE_PREFERENCE:
      return {
        ...state,
        singlePreference: action.payload,
      };
    case GET_SINGLE_JOUNRAL:
        return {
          ...state,
          singleJounral: action.payload,
        };  
    case GET_CATEGORIES:
      return {
        ...state,
        allCategories: action.payload,
      };
    case GET_SINGLE_CATEGORY:
      return {
        ...state,
        singleCategory: action.payload,
      };
    case GET_SUBCATEGORIES:
      return {
        ...state,
        allSubCategories: action.payload,
      };
    case GET_PROMPTCATEGORIES:
        return {
          ...state,
          promptCategories: action.payload,
        };  
    case GET_SINGLE_SUBCATEGORY:
      return {
        ...state,
        singleSubcategory: action.payload,
      };
    case GET_VOICES:
      return {
        ...state,
        allVoices: action.payload,
      };
    case GET_SINGLE_VOICE:
      return {
        ...state,
        singleVoice: action.payload,
      };
    case GET_CHARACTERS:
      return {
        ...state,
        allCharacters: action.payload,
      };
    case GET_SINGLE_CHARACTER:
      return {
        ...state,
        singleCharacter: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case GET_SINGLE_USER:
      return {
        ...state,
        singleUser: action.payload,
      };
    case GET_JOURNALS:
      return {
        ...state,
        allJournals:action.payload
      }  
    case GET_AFFIRMATIONS:
      return {
        ...state,
        allAffirmations: action.payload,
        affirmationsLoaded: true,
      };
    case GET_SINGLE_AFFIRMATION:
      return {
        ...state,
        singleAffirmation: action.payload,
      };
    case GET_THEME_CATEGORIES:
      return {
        ...state,
        allThemesCategories: action.payload,
      };

  case GET_THEME_CATEGORIES_DATA:
        return {
          ...state,
          allThemesCategoriesData: action.payload,
        };
    case GET_SINGLE_THEME_CATEGORY:
        return {
          ...state,
          singleThemeCategory: action.payload,
        };
    case GET_THEMESANDFONTS:
      return {
        ...state,
        allThemesAndFonts: action.payload,
      };
    case GET_SINGLE_THEME:
      return {
        ...state,
        singleTheme: action.payload,
      };
    case FAQS:
      return {
        ...state,
        faqs: action.payload,
      };
    case ADMIN_PROFILE:
      return {
        ...state,
        adminProfile: action.payload,
      };
    case CATEGORY_TRANSLATIONS:
      return {
        ...state,
        categoryTranslations: action.payload,
      };
    case GET_SINGLE_TEXT_CONTENTS:
        return {
          ...state,
          singleTextContent: action.payload,
        };
    case GET_TEXT_CONTENTS:
      return {
        ...state,
        allTextContents: action.payload,
      };
    case GET_AFFIRMATIONS_TO_EXPORT:
      return {
        ...state,
        allAffirmationstoExport: action.payload,
      };

    default:
      return state;
  }
}
