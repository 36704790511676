// ! Production;
 export const image_url = "https://api.appservercentral.com/";
export const new_image_url = "https://d25butgulnesvg.cloudfront.net/";
export const app_url = "https://api.appservercentral.com/api/v1/admin";
export const api_url = "https://api.appservercentral.com/api/v1/admin";
export const animated_theme_category = "6461df579b4858aacf28d3df";

// ! Local
// export const image_url = "http://localhost:5050/";
// export const app_url = "http://localhost:5050/api/v1/admin";
// export const api_url = "http://localhost:5050/api/v1/admin";
